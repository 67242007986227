import React, { useEffect, useRef, useState } from 'react'
import { Button, Header, Input } from '../../components'
import Alert from '../../components/Alert'
import { IoIosSearch } from 'react-icons/io';

import { useNavigate } from 'react-router-dom';
import axiosClient from '../../axios-client';
import { useStateContext } from '../../contexts/ContextProvider';
import { toast } from 'react-toastify';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { FaRegFileExcel } from 'react-icons/fa6';
import { BsPlusCircleFill, BsTrash } from 'react-icons/bs';
import Loading from '../../components/Loading';
import ActionButton from '../../components/ActionButton';
import AddAbsence from './AddAbsence';
import Swal from 'sweetalert2';
import Select from 'react-select';
import { LuMousePointerClick } from 'react-icons/lu';


const Absences = () => {
    const [errors, setErrors] = useState(null);

    const [loading, setLoading] = useState(false);
    const [absences, setAbsences] = useState([]);
    const [showAddAbsence, setShowAddAbsence] = useState(false);
    const [classes, setClasses] = useState([]);
    const [selectedClasse, setSelectedClasse] = useState(null);
    const [inputValueClasse, setValueClasse] = useState('');
    const { token, setToken, user } = useStateContext();
    const tableRef = useRef();
    const [req, setReq] = useState({
        DateDebut: '',
        DateFin: '',
        exp: '',
        classe: '',
    });
    const navigate = useNavigate();


    useEffect(() => {
        getClasses();
    }, [])


    const closeAddAbsence = () => {
        setShowAddAbsence(false);
    }
    const getAbsences = () => {
        setLoading(true);
        axiosClient.get('abscences?DateDebut=' + req.DateDebut + '&DateFin=' + req.DateFin + '&classe='+req.classe+'&exp=' + req.exp)
            .then(response => {
                setAbsences(response.data.absences);
                setLoading(false);
                setErrors(null);
            }).catch(err => {
                const response = err.response;
                if (response.status === 401) {
                    setToken(null);
                }
                else {
                    if (response.status === 422) {
                        setErrors(response.data.errors);
                        setLoading(false);
                        toast.error('Une erreur est survenue!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                    } else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }

                }
            })
    }

    const getClasses = () => {
        axiosClient.get('/classes')
            .then((data) => {
                setClasses(data.data.classes);
            }).catch(err => {
                const response = err.response;
                if (err.response.status === 401) {
                    setToken(null);
                } else {
                    if (response.status === 401) {
                        setToken(null);
                    }
                    else {
                        if (response.status === 403) {
                            navigate('/403');
                        } else {
                            if (response.status === 404) {
                                navigate('/404');
                            }
                            else {
                                navigate('/500');
                            }
                        }
                    }
                }
            })
    }
    const handleInputChangeClasse = value => {
        setValueClasse(value);
    };

    // handle selection


    // handle selection
    const handleChangeClasse = value => {
        setSelectedClasse(value);
        setReq({ ...req, classe: value.id });
    }

    const supprimer = (absence) => {
        Swal.fire({
            title: "Confirmation",
            text: "Etes vous sûre de supprimer cette element ?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#2196F3",
            cancelButtonColor: "#d33",
            confirmButtonText: "Oui!",
            cancelButtonText: "Non!"
        }).then((result) => {
            if (result.isConfirmed) {
                axiosClient.delete('/abscence/' + absence.id)
                    .then(response => {
                        toast.success('Opération effectuée avec succès!', {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        getAbsences();
                    }).catch(err => {
                        const response = err.response;

                        if (response.status === 401) {
                            setToken(null);
                        }
                        else {
                            if (response.status === 403) {
                                navigate('/403');
                            } else {
                                if (response.status === 404) {
                                    navigate('/404');
                                }
                                else {
                                    navigate('/500');
                                }
                            }
                        }


                    })
            }
        });
    }


    return (
        <>
            <Header title="Absences" icon={<LuMousePointerClick />} />
            {errors &&
                Object.keys(errors).map(key => (
                    <Alert key={key} message={errors[key][0]} />
                ))
            }
            <div className='flex gap-2 w-full flex-wrap md:flex-nowrap lg:flex-nowrap xl:flex-nowrap justify-between items-center'>
                <Input label="Date debut" type="date" value={req.DateDebut} onChange={ev => setReq({ ...req, DateDebut: ev.target.value })} required="required" />
                <Input label="Date fin" type="date" value={req.DateFin} onChange={ev => setReq({ ...req, DateFin: ev.target.value })} required="required" />
                <div className="flex-col w-full">
                    <label className="block text-sm font-medium leading-6 text-gray-900">Classe <span className='text-red-500'>*</span></label>
                    <Select className='mt-2 outline-1 ' placeholder="Classe..." cacheOptions defaultOptions value={selectedClasse} getOptionLabel={e => e.libelle} getOptionValue={e => e.id} options={classes} onInputChange={handleInputChangeClasse} onChange={handleChangeClasse} />
                </div>
                <div className=' flex gap-2 mt-8'>
                    <Button onClick={() => getAbsences()} color="bg-yellow-500" icon={<IoIosSearch />} size="20" text="Chercher" textColor="text-white" />
                    <DownloadTableExcel
                        filename="Absences"
                        sheet="absences"
                        currentTableRef={tableRef.current}
                    >
                        <Button color="bg-green-500" icon={<FaRegFileExcel />} size="20" text="Exporter" textColor="text-white" />

                    </DownloadTableExcel>
                </div>
            </div>
            {
                user.role && user.role.name === 'caissier' ? <div className="flex my-4">
                    <Button onClick={() => setShowAddAbsence(true)} color="bg-blue-500" icon={<BsPlusCircleFill />} size="20" text="Ajouter" textColor="text-white" />
                </div>
                    : <></>
            }

            <div className='flex mt-3 overflow-auto'>
                <table ref={tableRef} className='table-auto' >
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Matiere</th>
                            <th>Eleve</th>
                            <th>User</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    {loading ? <tbody><tr className='content-center items-center'><td className='content-center items-center' colSpan="6"><Loading /></td></tr></tbody> :
                        <tbody>
                            {absences.map(o => (
                                <tr>
                                    <td>{o.dateAbsence}</td>
                                    <td>{o.matiereLibelle}</td>
                                    <td>{o.eleve}</td>
                                    <td>{o.enseignant}</td>
                                    <td><ActionButton onClick={() => { supprimer(o) }} color="bg-red-500" icon={<BsTrash />} textColor="text-white" title="Supprimer" /></td>
                                </tr>
                            ))

                            }
                        </tbody>
                    }

                </table>
            </div>
            {
                showAddAbsence ? <AddAbsence onClose={closeAddAbsence} /> : <></>
            }

        </>
    )
}

export default Absences